<template>
  <v-dialog v-model="dialog" width="800">
    <perseu-card :title="titleDialog">
      <template slot="content">
        <v-form ref="form" lazy-validation>
          <v-text-field
            v-model.trim="legend.acronym"
            label="Sigla da legenda"
            :maxlength="20"
            counter="20"
            :rules="[$rules.required]"
          />
          <v-textarea
            class="mt-5"
            v-model.trim="legend.explanation"
            label="Significado da legenda"
            :rules="[$rules.required]"
            no-resize
            rows="4"
            outlined
            counter="255"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn color="secondary" @click="submitForm">Salvar</v-btn>
      </template>
    </perseu-card>
  </v-dialog>
</template>

<script>
import { save } from "@/services/legends-service";

const initialState = {
  acronym: "",
  explanation: "",
};
export default {
  data: () => ({
    legend: initialState,
    dialog: false,
  }),
  computed: {
    titleDialog() {
      return (this.legend.id ? "Edição de" : "Nova") + " Legenda";
    },
  },
  methods: {
    create() {
      this.legend = initialState;
      this.dialog = true;
      this.$refs.form?.resetValidation();
    },
    edit(legend) {
      this.legend = legend;
      this.dialog = true;
    },
    async submitForm() {
      try {
        this.$store.dispatch("loading/openDialog");
        if (!this.$refs.form.validate()) return;
        const response = await save(this.legend);
        if (this.legend.id) {
          this.$emit("update", response.data);
        } else {
          this.$emit("create", response.data);
        }
        this.dialog = false;
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$toasted.global.defaultError();
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style></style>
